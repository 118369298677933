<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.eexperience.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <!--                <CButton color="primary" v-on:click="createTableRow" v-if='checkPermission("create-post")'-->
                <!--                         style="margin-right: 10px;">-->
                <!--                  {{ $lang.buttons.general.crud.create }}-->
                <!--                </CButton>-->
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'E-Experience',self.userId)">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <!--                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>-->
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>


          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'E-Experience',self.userId)">
            <template #status="data">
              <span v-if="data.row.status === 'Active'">
                <CBadge :color="statusBudget(data.row.status)">Open</CBadge>
              </span>
              <span v-if="data.row.status !== 'Active'">
                <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
              </span>
            </template>
            <template #description="data">
              <span :title="data.row.description">{{ trimfunction(data.row.description, 20) }}</span>
            </template>
            <template #createdAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
            </template>
            <template #updatedAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.updatedAt) }}
            </template>
            <template #userWithCompany="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.userWithCompany"
                  :allowCompany="true"
                  :length="20"
              />
            </template>
            <template #actions="data">
              <!--v-if='checkPermission("view-post")'-->
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="details(data.row.id)"
                  v-if="checkPermission('list-e-experience')"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-e-experience')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {
  EExperience
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "EExperience",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      statusLabel: "Block",
      statusColor: "danger",
      selectedColumns: [],
      messageColor: 'success',
      iAmUser: "",
      userId: "",
      listUrl: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      postTypeList: [],
      industryOptions: [],
      subIndustryOptions: [],
      resourceTypeOptions: [],
      currencyOptions: [],
      visibilityOptions: [
        {
          value: 1,
          label: "Local",
        },
        {
          value: 2,
          label: "National",
        },
        {
          value: 3,
          label: "International",
        },
      ],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      userOptions: [],
      remoteWorkOptions: [],
      profileVerifiedOptions: [],

      activePage: 1,
      module: EExperience,
      eexperience: {
        isActive: '',
      },
      columns: [
        "companyName",
        "empId",
        "firstName",
        "lastName",
        "panNumber",
        "aadhaarNumber",
        "createdAt",
        "updatedAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          companyName: this.$lang.eexperience.table.companyName,
          firstName: this.$lang.eexperience.table.firstName,
          lastName: this.$lang.eexperience.table.lastName,
          panNumber: this.$lang.eexperience.table.panNumber,
          aadhaarNumber: this.$lang.eexperience.table.aadhaarNumber,
          empId: this.$lang.eexperience.table.empId,
          createdAt: this.$lang.common.table.createdAt,
          updatedAt: this.$lang.common.table.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = "/e_experience/list/";
  },
  mounted() {
    let self = this;
    // this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    self.userId = this.$route.params.user_id;
    if (typeof self.userId !== "undefined" || self.userId != null) {
      self.listUrl = this.listUrl + self.userId;
    } else {
      self.listUrl = this.listUrl;
    }
    //Filter Section
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);

    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {


    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    details(item) {
      this.$router.push({path: `e-experience/view/${item}`});
    },
    viewMediaTableRow(item) {
      this.$router.push({path: `e-experience/media/list/${item}`});
    },
    viewReportTableRow(item) {
      this.$router.push({path: `e-experience/report/list/${item}`});
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
